<script setup lang="ts">

import { ref } from 'vue';
import type {Schemas} from "~/api-types/storeApiTypes";
const dynamicIcon = ref('');

const props = defineProps<{
  badge: Schemas["HwBadge"]
}>()

// Dynamically import the icon
import('@mdi/js').then((icons) => {
  dynamicIcon.value = (icons[props.badge.icon as string]  ?? '') as string;
});

</script>

<template>
  <v-chip density="comfortable" :prepend-icon="dynamicIcon" class="bg-gray700 mr-2 font-weight-medium">
    {{ badge.name }}
  </v-chip>
</template>
